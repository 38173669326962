<template>
  <p id="vote-title">{{ votingName() }}</p>
  <Suspense>
    <MayorityVote />
  </Suspense>
</template>

<script>
import MayorityVote from "@/components/MayorityVote.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    MayorityVote,
  },
  setup() {
    const route = useRoute();

    function votingName() {
      return route.query.name;
    }

    return {
      votingName,
    };
  },
};
</script>

<style scoped>
.scoring-vote-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#vote-title {
  font-weight: bold;
  font-size: 3vw;
  margin: 6%;
  text-align: center;
}
</style>
