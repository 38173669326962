<template>
  <HeaderBar v-if="!$route.meta.hideNavbar" />
  <router-view />
</template>

<script>
import HeaderBar from "./components/HeaderBar.vue";

export default {
  name: "App",
  components: {
    HeaderBar,
  },
};
</script>

<style></style>
