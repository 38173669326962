<template>
  <h1 id="voting-title">Delegado GG1 Informática</h1>
  <ScoringOptions :options="['Raul', 'Pepe', 'Juan']" />
</template>

<script>
export default {
  name: "VotingView",
  components: {},
};
</script>

<style></style>
