<template>
  <div id="container">
    <h3>Votaciones Privadas ({{ votaciones.length }})</h3>
    <hr />
    <li v-for="votacion in votaciones" :key="votacion.id" id="votes-list">
      <VotingCard
        :name="votacion.name"
        :winners="votacion.winners"
        :votingSystem="votacion.voting_system"
        :identifier="votacion.id"
        class="card"
      />
    </li>
  </div>
</template>

<script>
import VotingCard from "./VotingCard.vue";
import axios from "axios";
export default {
  name: "PrivateVotes",
  async setup() {
    const number = 0;
    let votaciones = [];

    function accessToken() {
      return localStorage.getItem("accessToken");
    }

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_BACK_URL}/api/v1/voting/private`,
        {
          headers: {
            // eslint-disable-next-line no-undef
            Authorization: `Bearer ${accessToken()}`,
          },
        }
      );
      votaciones = response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.setItem("accessToken", null);
          window.location.reload();
        }
      }
    }
    console.log(votaciones);
    return { number, votaciones };
  },
  components: { VotingCard },
};
</script>

<style scoped>
h3 {
  font-size: xx-large;
  padding: 5px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  margin: 3%;
}

#card {
  width: fit-content;
  height: fit-content;
  background-color: bisque;
}

#container {
  margin-top: 2vw;
  text-align: center;
}

@media (max-width: 700px) {
  h3 {
    font-size: large;
  }

  li {
    display: inline-block;
    margin: 5%;
  }
}
</style>
