<template>
  <div class="form-container">
    <el-form
      ref="ruleFormRef"
      style="max-width: 600px"
      :model="ruleForm"
      label-width="auto"
      size="default"
      status-icon
    >
      <el-form-item label="Id de la Votación" prop="token">
        <el-input v-model="ruleForm.voting_id" style="width: 25vw" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          Buscar
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";

const ruleFormRef = ref();
const ruleForm = reactive({
  voting_id: "",
});

const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      window.location.replace(`/reports/${ruleForm.voting_id}.pdf`);
    }
  });
};
</script>

<style scoped>
.form-container {
  display: flex;
  justify-content: center;
}
</style>
